<template>
  <div class="blog-widget">
    <section v-if="blog !== 'error'">
        <p class="titulo"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700">ÚLTIMAS ENTRADAS</p>
        <div class="blog-w-l">
        <div class="bwl-item " v-for="(b, i) in blog" :key="i"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700">
            <a :href="'/blog/'+b.url">
                <section class="bloq-i dflx">
                    <img :src="srcImg(b.image)" alt="img" class="bwl-img" />
                    <div class="bwl-info">
                    <p class="cat">{{ b.category_name }}</p>
                    <p class="titulo-b">{{ b.title }}</p>
                    <a :href="'/blog/'+b.url" class="read-more">Leer más > </a>
                    </div>            
                </section>            
            </a>
        </div>
        </div>        
    </section>

    <a href=""
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700">
      <div class="ad-cont"></div>
    </a>
    <form action=""
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700">
      <div class="search-c dflx">
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Buscar en Blog"
          v-model="searchBlo"
        />
        <input type="submit" value="" class="lupa-btn"/>
      </div>
    </form>
    <div class="tags-w-c" v-if="tag !== 'error'">
      <div class="tag" v-for="(t, i) in tag" :key="i"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700">{{ t.name }}</div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
let urlpath = require("../../global/url");
export default {
  data() {
    return {
      searchBlo:'',
    }
  },
  async created() {
    await this.getAllInfoBlo2("blog");
    await this.getAllInfoTg("tag");
  },
  computed: {
    blog() {
      let data = this.$store.getters["blog/data2"];
      if(data == 'error'){
        return 'error'
      }else{
        return data.slice(0, 4);        
      }
    },
    tag() {
      return this.$store.getters["tag/data"];
    },
  },
  methods: {
    ...mapActions("blog", ["getAllInfoBlo2"]),
    ...mapActions("tag", ["getAllInfoTg"]),

    srcImg: function (img) {
      let src = `${urlpath.url()}/P6H_3Pm/bt7-EO4/blog-img/${img}`;
      return src;
    },
  },
};
</script>
<style>
.blog-widget a{
    text-decoration-color: transparent;
}
.blog-widget .titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 5.841vw;
  line-height: 8.879vw;
  color: #fe9128;
}
img.bwl-img {
  border-radius: 23.364vw;
  width: 19.626vw;
  height: 19.626vw;
  object-fit: cover;
}
.bwl-item {
  border-bottom: 0.05vw solid gainsboro;
  margin-bottom: 3.271vw;
}
.bwl-item:nth-child(4) {
  border-bottom: none;
}
.bwl-info {
  padding: 3.271vw;
}
.bwl-info p {
  margin: 0;
  font-family: "Helvetica World Regular";
}
.bwl-info p.cat {
  font-weight: 400;
  font-size: 3.271vw;
  line-height: 4.673vw;
  color: #3d9635;
}
.bwl-info p.titulo-b {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 3.271vw;
  line-height: 3.972vw;
  color: #0a2b12;
}
.bwl-info a.read-more {
  font-weight: 400;
  font-size: 2.336vw;
  line-height: 3.271vw;
  color: #fe9128;
  text-decoration-color: transparent;
}
.search-c {
  margin: 10.514vw 0;
}
.search-c #search {
  width: 54.439vw;
  height: 15.888vw;
  border: 0.117vw solid #929292;
  border-right: 0;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #929292;
}
.search-c .lupa-btn {
  width: 19.393vw;
  height: 15.888vw;
  background-color: #70a847;
  background-image: url("../../assets/lupa-s.svg");
  background-size: 6.075vw;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}
.tags-w-c {
  display: flex;
  flex-wrap: wrap;
}
.tags-w-c .tag {
  font-weight: 400;
  font-size: 3.271vw;
  line-height: 7.009vw;
  color: #c5c597;
  background: #ededd3;
  width: fit-content;
  padding: 0 4vw;
  margin-right: 1.8vw;
}
.ad-cont {
  width: 100%;
  height: 61.449vw;
  margin-top: 16.121vw;
  background-image: url("../../assets/adv.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .blog-widget .titulo {
    font-size: 1.25vw;
    line-height: 1.979vw;
    margin: 0 0 1.406vw;
  }
  img.bwl-img {
    border-radius: 13.364vw;
    width: 4.375vw;
    height: 4.375vw;
  }
  .bwl-info {
    padding: 0.729vw;
  }
  .bwl-item {
    padding-bottom: 0.885vw;
    margin-bottom: 0.885vw;
  }
  .bwl-info p.cat {
    font-size: 0.729vw;
    line-height: 1.042vw;
  }
  .bwl-info p.titulo-b {
    font-size: 0.729vw;
    line-height: 0.885vw;
  }
  .bwl-info a.read-more {
    font-size: 0.521vw;
    line-height: 0.729vw;
  }
  .search-c {
    margin: 2.76vw 0 1.146vw;
  }
  .search-c #search {
    width: 14.479vw;
    height: 2.083vw;
  }
  .search-c .lupa-btn {
    width: 2.865vw;
    height: 2.083vw;
    background-size: 1vw;
  }
  .bwl-item:nth-child(4) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .tags-w-c .tag {
    font-size: 0.729vw;
    line-height: 1.563vw;
    padding: 0 0.833vw;
    margin-right: 0.68vw;
  }
  .ad-cont {
    height: 13.698vw;
    margin-top: 3.594vw;
  }
  .bloq-i {
    transition: .5s;
  }
  .bloq-i:hover {
    transform: scale(1.05);
  }
}
</style>